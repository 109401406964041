import React, {Component} from 'react';
import {connect} from 'react-redux';
import './UserProfileContainer.css';
import "../../../fontello/css/fontello.css"
import hmsParse from 'hms-parse';
import {setPersonalDataBtn} from '../../../store/actions/ProfileContainer'
import {withRouter} from "react-router-dom";
import {loggingService} from "../../../services/Logging";

//import {loggingService} from "../../../services/Logging";

class UserProfileContainer extends Component {

    constructor(props) {
        super(props);
        if (props.user !== null)
            this.loadPersonalDataBtn();
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    // static getDerivedStateFromProps = (props) => {
    //     loggingService("UserProfileContainer.getDerivedStateFromProps - props:");
    //     loggingService(props);
    //     if (props.user === null) {
    //         //loggingService("UserProfileContainer.getDerivedStateFromProps - before history.push");
    //         //props.history.push("/");
    //     }
    //     return null;
    // };

    shouldComponentUpdate(nextProps, nextState) {
        loggingService("UserProfileContainer.shouldComponentUpdate");
        if (nextProps.user === null) {
            this.props.history.push("/");
            return false;
        }
        return true;
    }

    getSubscription = () => {
        if (this.props.user.isPremium) {
            //TODO: muestro la informacion de premium
            this.props.setPersonalDataBtn(false);
        } else {
            this.props.history.push("/");
        }
    };

    getUserProfilePicture = () => {
        // console.log('profilePicture in UserProfileContainer: ' + this.props.user.profilePicture);
        if (this.props.user.profilePicture) {
            return this.props.user.profilePicture;
        }
    };

    showPremium = () => {
        if (this.props.user.isPremium)
            return <span className="userProfileContainer_userStatsContainer_isPremium">Premium</span>
    };

    getTrainedSeconds = () => {
        if (this.props.user.userStats !== null) {
            return hmsParse.fromSeconds(this.props.user.userStats.trainedSeconds);
        } else {
            return '0s';
        }
    };

    getCaloriesBurned = () => {
        if (this.props.user.userStats !== null) {
            return this.props.user.userStats.caloriesBurned.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return 0;
        }
    };

    getWorkouts = () => {
        if (this.props.user.userStats !== null) {
            return this.props.user.userStats.workoutsDone;
        } else {
            return 0;
        }
    };

    getChallenges = () => {
        if (this.props.user.userStats !== null) {

        } else {
            return 0;
        }
        return this.props.user.userStats.challengeDone;
    };

    getRecipes = () => {
        if (this.props.user.userStats !== null) {
            return this.props.user.userStats.recipesDone;
        } else {
            return 0;
        }
    };

    getFavorites = () => {
        let totalFavorites = 0;
        if (this.props.user.userStats !== null) {
            if (this.props.user.favorites !== null) {

                let favoriteArticles = 0;
                if (typeof this.props.user.favorites.articles !== 'undefined')
                    favoriteArticles = this.props.user.favorites.articles.length;

                let favoriteChallenges = 0;
                if (typeof this.props.user.favorites.challenges !== 'undefined')
                    favoriteChallenges = this.props.user.favorites.challenges.length;

                let favoriteRecipes = 0;
                if (typeof this.props.user.favorites.recipes !== 'undefined')
                    favoriteRecipes = this.props.user.favorites.recipes.length;

                let favoriteWorkouts = 0;
                if (typeof this.props.user.favorites.workouts !== 'undefined')
                    favoriteWorkouts = this.props.user.favorites.workouts.length;

                totalFavorites = favoriteArticles + favoriteChallenges + favoriteRecipes + favoriteWorkouts;
            }
        }

        return totalFavorites;
    };

    getGender = () => {
        if (this.props.user.gender === 0) {
            return "Femenino";
        }
        return "Masculino";
    };

    loadPersonalDataBtn = () => {
        if (this.props.user.isPremium) {
            this.props.setPersonalDataBtn(false);
        } else {
            this.props.setPersonalDataBtn(true);
        }
    };

    setPersonalDataBtn = () => {
        this.props.setPersonalDataBtn(true);
    };

    getPersonalDataSection = () => {
        return (
            <div className="userProfileContainer_personalData">
                <div className="userProfileContainer_personalData_label">Nombre y apellido</div>
                <div className="userProfileContainer_personalData_data">{this.props.user.userName}</div>
                <div className="userProfileContainer_personalData_label">G&eacute;nero</div>
                <div className="userProfileContainer_personalData_data">{this.getGender()}</div>
                <div className="userProfileContainer_personalData_label">Correo electr&oacute;nico</div>
                <div className="userProfileContainer_personalData_data">{this.props.user.email}</div>
            </div>
        );
    };

    getSubscriptionInformation = () => {
        if (this.props.user.isPremium) {
            return (
                <div className="userProfileContainer_SubscriptionInformation">
                    <div className="userProfileContainer_SubscriptionInformation_nextRenovation">
                        <div className="userProfileContainer_SubscriptionInformation_nextRenovation_title">Siguiente
                            renovaci&oacute;n
                        </div>
                        <div className="userProfileContainer_SubscriptionInformation_nextRenovation_date">23 / 04 / 2020
                        </div>
                    </div>
                    <div
                        className="userProfileContainer_SubscriptionInformation_nextRenovation_text">T&uacute; renovaci&oacute;n
                        al Coach ORUX se renovar&aacute; de forma autom&aacute;tica durante 12 meses el 23/04/2020 por
                        17.99
                        US$, a menos que canceles la suscripci&oacute;n antes de esa fecha
                    </div>
                    <button className="userProfileContainer_SubscriptionInformation_cancelSubscriptionBtn">CANCELAR
                        SUSCRIPCI&Oacute;N
                    </button>
                    <div className="userProfileContainer_SubscriptionInformation_horizontalLine"></div>
                    <div className="userProfileContainer_SubscriptionInformation_payMethod">
                        <div className="userProfileContainer_SubscriptionInformation_payMethod_title">M&eacute;todo de
                            pago
                        </div>
                        <div className="userProfileContainer_SubscriptionInformation_payMethod_method">Tarjeta de
                            cr&eacute;dito
                        </div>
                    </div>
                </div>
            );
        }
    };

    render() {
        loggingService("UserProfileContainer.render - props");
        loggingService(this.props);
        if (this.props.user === null) {
            loggingService("UserProfileContainer.render.if");
            this.props.history.push("/");
            return null;
        } else {
            return (
                <div className="userProfileContainer">

                    <div className="userProfileContainer_userStatsContainer">

                        <img src={this.getUserProfilePicture()}
                             className="userProfileContainer_userStatsContainer_picture" alt=""/>

                        {this.showPremium()}

                        <div className="userProfileContainer_userStatsContainer_title">Estad&iacute;sticas</div>

                        <div className="userProfileContainer_userStatsContainer_stats_one">
                            <div className="userProfileContainer_userStatsContainer_stats_one_text">Tiempo de
                                entrenamiento
                            </div>
                            <div
                                className="userProfileContainer_userStatsContainer_stats_one_value">{this.getTrainedSeconds()}</div>
                            <div className="userProfileContainer_userStatsContainer_stats_one_vertical_line"></div>
                            <div className="userProfileContainer_userStatsContainer_stats_one_text">Calor&iacute;as
                                quemadas
                            </div>
                            <div
                                className="userProfileContainer_userStatsContainer_stats_one_value">{this.getCaloriesBurned()}</div>
                        </div>


                        <div className="userProfileContainer_userStatsContainer_stats_two">
                            <div className="userProfileContainer_userStatsContainer_stats_two_section">
                                <i className="icon-stopwatch"/>
                                <span>Rutinas</span>
                                <span
                                    className="userProfileContainer_userStatsContainer_stats_two_section_value">{this.getWorkouts()}</span>
                            </div>
                            <div className="userProfileContainer_userStatsContainer_stats_two_section">
                                <i className="icon-trophy"/>
                                <span>Retos</span>
                                <span
                                    className="userProfileContainer_userStatsContainer_stats_two_section_value">{this.getChallenges()}</span>
                            </div>
                            <div className="userProfileContainer_userStatsContainer_stats_two_section">
                                <i className="icon-food"/>
                                <span>Recetas</span>
                                <span
                                    className="userProfileContainer_userStatsContainer_stats_two_section_value">{this.getRecipes()}</span>
                            </div>
                            <div className="userProfileContainer_userStatsContainer_stats_two_section">
                                <i className="icon-star"/>
                                <span>Favoritos</span>
                                <span
                                    className="userProfileContainer_userStatsContainer_stats_two_section_value">{this.getFavorites()}</span>
                            </div>
                        </div>

                        <div className="userProfileContainer_buttonsContainer">
                            <button
                                className={this.props.profileContainer.personalDataBtn ? "userProfileContainer_buttonsContainer_personalData btn_selected" : "userProfileContainer_buttonsContainer_personalData"}
                                onClick={() => this.setPersonalDataBtn()}>DATOS PERSONALES
                            </button>
                            <button
                                className={this.props.profileContainer.personalDataBtn ? "userProfileContainer_buttonsContainer_subscription" : "userProfileContainer_buttonsContainer_subscription btn_selected"}
                                onClick={() => this.getSubscription()}>SUSCRIPCI&Oacute;N
                            </button>
                        </div>

                        {this.props.profileContainer.personalDataBtn ? this.getPersonalDataSection() : this.getSubscriptionInformation()}

                    </div>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({
    setPersonalDataBtn: payload => dispatch(setPersonalDataBtn(payload))
});

const mapStateToProps = state => ({
    user: state.user,
    profileContainer: state.profileContainer
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfileContainer));