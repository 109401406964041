export const SET_USER_DATA = 'SET_USER_DATA';
export const ADD_USER = 'ADD_USER';
export const FINISH_SING_UP = 'FINISH_SING_UP';
export const LOGOUT = 'LOGOUT';

export const setUserData = payload => ({
    type: SET_USER_DATA,
    payload
});

export const addUser = payload => ({
    type: ADD_USER,
    payload
})

export const finishSingUp = payload => ({
    type: FINISH_SING_UP,
    payload
})

export const logout = payload => ({
    type: LOGOUT,
    payload
})