import React, {Component} from 'react';
import {connect} from 'react-redux';
import './SummaryPurchaseContainer.css';
import {setSelectedSubscription} from '../../store/actions/PurchaseInformation'
import {withRouter} from 'react-router-dom';
import {paypal} from '../../services/Paypal';
import {findDiscountCodeByDocId} from '../../services/Firebase/DiscountCodes.js';
import {ORUX_, INGRESAR_C_D_D} from "../../services/Constants.js";
import {getPriceForSubscription} from "../../services/SelectedSubscription";
import {loggingService} from "../../services/Logging.js";
import {updatePaypalSubscriptionOnFirebase} from "../../services/Firebase/Users.js"

class SummaryPurchaseContainer extends Component {

    constructor(props) {
        console.log('SummaryPurchaseContainer.constructor');
        super(props);
        this.state = {
            inputValue: INGRESAR_C_D_D,
            isDiscountApplied: false,
            totalPriceWithDiscount: 0,
            discountPercent: 0
        };
        this.paypalButton = React.createRef();
    }

    /**
     * Luego de ejecutado el constructor, se ejecuta este metodo que pertenece al ciclo de vida de react.
     * @param props
     * @returns {null}
     */
    static getDerivedStateFromProps = props => {
        if (!props.user) {
            props.history.push("/");
        }
        return null;
    };

    promotionalCodeInputOnClick = () => {
        console.log("SummaryPurchaseContainer.onClickInput");
        if (this.state.inputValue === INGRESAR_C_D_D) {
            this.setState({
                inputValue: ORUX_
            });
        }
    }

    promotionalCodeInputOnChange = (event) => {
        console.log("SummaryPurchaseContainer.onChangeInput");
        this.setState({
            inputValue: event.target.value
        });

        if (event.target.value.length < 10 && this.state.isDiscountApplied) {
            //Se resetean los valores
            this.setState({
                isDiscountApplied: false,
                totalPriceWithDiscount: 0,
                discountPercent: 0
            })
        }

        if (event.target.value.length === 10) {
            findDiscountCodeByDocId(event.target.value)
                .then(discountCode => {
                    if (discountCode !== null) {
                        if (discountCode.active) {
                            //si el codigo esta activo (true), se puede usar
                            let totalPriceTmp = 0;
                            let priceWithDiscount = 0;
                            const price = getPriceForSubscription(this.props.purchaseInformation.selectedSubscription.monthSelected);
                            const discountPercent = discountCode.discount;
                            priceWithDiscount = price * (discountPercent / 100);
                            totalPriceTmp = price - priceWithDiscount;
                            loggingService("priceWithDiscount: " + priceWithDiscount);
                            loggingService("totalPriceTmp: " + totalPriceTmp);
                            this.setState({
                                totalPriceWithDiscount: totalPriceTmp,
                                isDiscountApplied: true,
                                discountPercent
                            })
                            //Habilito el boton de paypal para compra unica y no suscripcion
                            this.enableOrderInPaypalButton();
                        } else {
                            //codigo no esta activo
                            //TODO: el codigo no esta activo, mostrar modal
                        }
                    } else {
                        //no se encontro el codigo
                        //TODO: mostrar modal
                    }
                })
                .catch(error => {

                });
        }
    }

    enableSubscriptionInPaypalButton = () => {
        loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton");
        //Limpio el boton antes del renderizado
        this.paypalButton.current.innerHTML = null;
        let planId = null;
        if (this.props.purchaseInformation.selectedSubscription.monthSelected === 3) {
            planId = "P-0TK26510GA2185830L3IDB4A";
        } else if (this.props.purchaseInformation.selectedSubscription.monthSelected === 6) {
            planId = "P-1HN69149V0430131PL3IDDJI";
        } else if (this.props.purchaseInformation.selectedSubscription.monthSelected === 12) {
            planId = "P-74R97785BS3610522L3IDEGI";
        }

        paypal.Buttons({
            style: {
                layout: 'horizontal',
                tagline: false,
                color: 'white'
            },

            createSubscription: function (data, actions) {
                return actions.subscription.create({
                    'plan_id': planId
                });
            },

            onApprove: (data, actions) => {

                loggingService("SummaryPurchaseContainer.enableSubscriptionInPaypalButton.onApprove - actions.subscription:");
                //TODO: analizar los actions
                loggingService(actions.subscription.get());
                loggingService(actions.subscription.activate());

                // actions.subscription.capture().then(function (details) {
                //     loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton.onApprove - details:");
                //     loggingService(details);
                //     //console.log('Transaction completed by ' + details.payer.name.given_name);
                // });

                updatePaypalSubscriptionOnFirebase(data, this.props.user.uid)
                    .then(() => {
                        loggingService("SummaryPurchaseContainer.enableSubscriptionInPaypalButton.onApprove.updatePaypalSubscriptionOnFirebase.then");
                        this.redirectToWelcomePage();
                    })
                    .catch((err) => {
                        //TODO: mostrar modal
                        loggingService("SummaryPurchaseContainer.enableSubscriptionInPaypalButton.onApprove.updatePaypalSubscriptionOnFirebase.catch - err:");
                        loggingService(err);
                    });
            },

            onCancel: function (data) {
                loggingService("SummaryPurchaseContainer.onCancel - data:");
                loggingService(data);
            },

            onError: function (err) {
                loggingService("SummaryPurchaseContainer.onError - err:");
                loggingService(err);
            }
        }).render('#paypal-button-container');
    }

    enableOrderInPaypalButton = () => {
        loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton");
        //Limpio el boton antes del renderizado
        this.paypalButton.current.innerHTML = null;

        let totalPriceWithDiscount = this.state.totalPriceWithDiscount;
        paypal.Buttons({
            style: {
                layout: 'horizontal',
                tagline: false,
                color: 'white'
            },
            createOrder: function (data, actions) {
                // This function sets up the details of the transaction, including the amount and line item details.
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: "USD",
                            value: totalPriceWithDiscount
                        }
                    }]
                });
            },
            onApprove: function (data, actions) {
                loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton.onApprove - data:");
                loggingService(data);
                return actions.order.capture().then(function (details) {
                    loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton.onApprove - details:");
                    loggingService(details);

                    //console.log('Transaction completed by ' + details.payer.name.given_name);
                });
            },
            onCancel: function (data) {
                loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton.onCancel - data:");
                loggingService(data);
                //TODO: procesar la cancelacion
            },
            onError: function (err) {
                loggingService("SummaryPurchaseContainer.enableOrderInPaypalButton.onError - err:");
                loggingService(err);
                //TODO: procesar el error
            }
        }).render('#paypal-button-container');
    }

    promotionalCodeInputOnBlur = () => {
        if (this.state.inputValue === ORUX_) {
            this.setState({
                inputValue: INGRESAR_C_D_D
            });
        }
    }

    redirectToWelcomePage = () => {
        this.props.history.push("/welcome");
    }

    //Metodo invocado despues de que se renderiza el componente
    componentDidMount = () => {
        loggingService("SummaryPurchaseContainer.componentDidMount");
        //Por defecto en el primer renderizado, activo la suscripcion en el boton de paypal
        this.enableSubscriptionInPaypalButton();
        window.scrollTo(0, 0)
        return null;
    };

    monthSelected = () => {
        return this.props.purchaseInformation.selectedSubscription.monthSelected;
    };

    getPrice() {
        if (this.state.isDiscountApplied) {
            return this.state.totalPriceWithDiscount;
        } else {
            return getPriceForSubscription(this.props.purchaseInformation.selectedSubscription.monthSelected);
        }
    }

    getPromotionalCodeInputValue = () => {
        return this.state.inputValue
    }

    getDiscountPercent = () => {
        if (this.state.isDiscountApplied) {
            return this.state.discountPercent + " %";
        }
    }

    getInfoClassName = () => {
        return this.state.isDiscountApplied ? "summaryPurchaseC_b_info" : "hiddenComponent";
    }

    getBoxClassName = () => {
        return this.state.isDiscountApplied ? "summaryPurchaseC_boxL summaryPurchaseC_box" : "summaryPurchaseC_box";
    }

    render() {
        return (
            <div className="summaryPurchaseContainer">
                <div className={this.getBoxClassName()}>
                    <div className="summaryPurchaseC_b_title">Tu resumen de compra</div>
                    <div className="summaryPurchaseC_b_months summaryPurchaseBoxMonths">{this.monthSelected()} meses
                    </div>
                    <div className="summaryPurchaseC_b_description">
                        - Planes personalizados de entrenamiento y nutrición<br/><br/><br/>
                        - Chat y asesoría con los coach's
                    </div>
                    <div className="summaryPurchaseC_b_total">Total: ${this.getPrice()} dólares</div>

                    <div className={this.getInfoClassName()}>Descuento del {this.getDiscountPercent()} aplicado
                    </div>
                    <div className={this.getInfoClassName()}><b>NOTA:</b> el uso de un código de descuento no genera
                        suscripcion (pago recurrente). Una vez
                        terminado el periodo de Plan Premium adquirido con el codigo de descuento, debera activar su
                        Plan Premium de nuevo.
                    </div>

                    <div className="summaryPurchaseC_b_horizontalLine"></div>
                    <div className="summaryPurchaseC_b_title">Codigo de descuento</div>
                    <input type="text" name="promotionalCode" className="summaryPurchaseC_b_promotionalCodeInput"
                           value={this.getPromotionalCodeInputValue()} onChange={this.promotionalCodeInputOnChange}
                           onClick={() => this.promotionalCodeInputOnClick()}
                           onBlur={() => this.promotionalCodeInputOnBlur()}
                           maxLength="10"></input>
                    <div className="summaryPurchaseC_b_title summaryPurchaseC_b_payWith">Pagar con</div>

                    <div className="summaryPurchaseC_b_buttonsContainer">
                        <div id="paypal-button-container"
                             className="summaryPurchaseC_b_bc_paypalButton" ref={this.paypalButton}></div>
                        <div id="payu"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSelectedSubscription: payload => dispatch(setSelectedSubscription(payload))
});

const mapStateToProps = state => ({
    purchaseInformation: state.purchaseInformation,
    user: state.user
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SummaryPurchaseContainer));