export const formatUserData = (userData, newUser) => {
    let profilePicture;
    if (typeof userData.profilePics !== 'undefined') {
        if (userData.profilePics.thumb128) {
            profilePicture = userData.profilePics.thumb128;
        } else if (userData.profilePics.google) {
            profilePicture = userData.profilePics.google;
        } else if (userData.profilePics.facebook) {
            profilePicture = userData.profilePics.facebook;
        } else if (false) {
            //apple
        }
    } else {
        profilePicture = "/images/default-avatar-user.jpg"
    }

    const userName = userData.profile.firstName + ' ' + userData.profile.lastName;
    const firstName = userData.profile.firstName;
    const lastName = userData.profile.lastName;
    const {isPremium} = userData.profile;
    const gender = userData.profile.gender;
    const userStats = userData.userStats;
    const email = userData.profile.email;
    const uid = userData.profile.uid;

    let favoritesTmp;
    if (typeof userData.favorites !== 'undefined') {
        favoritesTmp = userData.favorites;
    } else {
        favoritesTmp = null;
    }
    const favorites = favoritesTmp;

    return {
        profilePicture,
        userName,
        firstName,
        lastName,
        isPremium,
        userStats,
        gender,
        email,
        favorites,
        uid,
        newUser
    }
};