import {auth, db} from "../../config/Firebase";
import {loggingService} from "../Logging.js";
import {USERS} from "../../config/Firebase/DBCollectionListName";
import dateFormat from "dateformat-light";
import firebase from "firebase";
import {BLANK_SPACE, FACEBOOK_COM, GOOGLE_COM} from "../Constants.js"

/**
 *
 * @param type
 * @param data
 * @returns {Promise<unknown>}
 */
export const login = (type, data) => {
    if (type === 4) {
        //Login with email and password
        return loginWithEmailAndPassword(data);
    }

    let provider = null;
    if (type === 1) {
        //TODO: complete apple login
        //Login with Apple
    } else if (type === 2) {
        //Login with Facebook
        provider = new firebase.auth.FacebookAuthProvider();
    } else if (type === 3) {
        //Login with Google
        provider = new firebase.auth.GoogleAuthProvider();
    }
    return loginWithThirdPartyApps(provider);
}

//OK
/**
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const loginWithEmailAndPassword = data => {
    return new Promise((resolve, reject) => {
        auth.signInWithEmailAndPassword(data.email, data.password)
            .then(result => {
                if (result.user) {
                    findUserByUID(result.user.uid)
                        .then(doc => {
                            if (doc.exists) {
                                //doc.data contiene exclusivamente la informacion que se aprecia en Firebase Database
                                resolve(doc.data());
                            } else {
                                //El documento con el uid indicado no existe
                                loggingService("Users.loginWithEmailAndPassword.signInWithEmailAndPassword.then.if.findUserByUID.then.else - doc:");
                                loggingService(doc);
                                reject();
                            }
                        })
                        .catch(err => {
                            loggingService("Users.loginWithEmailAndPassword.signInWithEmailAndPassword.then.if.findUserByUID.then - err:");
                            loggingService(err);
                            reject(err);
                        })
                } else {
                    loggingService("Users.loginWithEmailAndPassword.signInWithEmailAndPassword.then.else - result:");
                    loggingService(result);
                    reject();
                }
            })
            .catch(err => {
                loggingService("Users.loginWithEmailAndPassword.signInWithEmailAndPassword.catch - err:");
                loggingService(err);
                reject(err);
            });
    })
}

//OK
/**
 *
 * @param provider
 * @returns {Promise<unknown>}
 */
export const loginWithThirdPartyApps = provider => {
    return new Promise((resolve, reject) => {
        firebaseAuth(provider)
            .then(result => {
                loggingService("Users.loginWithThirdPartyApps.firebaseAuth.then - result:")
                loggingService(result);
                const user = result.user;
                const additionalUserInfo = result.additionalUserInfo;
                if (user.uid) {
                    findUserByUID(user.uid)
                        .then(doc => {
                            if (doc.exists) {
                                loggingService("Users.loginWithThirdPartyApps.firebaseAuth.then.if.findUserByUID.then.if");
                                //doc.data contiene exclusivamente la informacion que se aprecia en Firebase Database
                                resolve(doc.data());
                            } else {
                                /**
                                 * Por algun motivo se encuentra el usuario en Firebase authentication pero no en Firebase
                                 * database. En este caso, se procede a crear el usuario en firebase database para que el usuario
                                 * pueda ingresar a la plataforma.
                                 */
                                loggingService("Users.loginWithThirdPartyApps.firebaseAuth.then.if.findUserByUID.then.else");
                                addUserWithUID(user.uid, additionalUserInfo)
                                    .then(result => {
                                        findUserByUID(user.uid)
                                            .then(doc => {
                                                if (doc.exists) {
                                                    resolve(doc.data());
                                                } else {
                                                    reject()
                                                }
                                            })
                                            .catch(err => {
                                                reject();
                                            })
                                    })
                                    .catch(err => {
                                        loggingService("Users.loginWithThirdPartyApps.firebaseAuth.then.if.findUserByUID.then.else.addUserWithUID.catch - err:");
                                        loggingService(err);
                                        reject(err);
                                    })
                            }
                        }).catch(err => {
                        loggingService("Users.loginWithThirdPartyApps.firebaseAuth.then.if.findUserByUID.catch - err:");
                        loggingService(err);
                        reject(err);
                    })
                } else {
                    //TODO: validar la respuesta de firebaseAuth
                    loggingService("Users.loginWithThirdPartyApps.firebaseAuth.then.else - result:");
                    loggingService(result);
                    reject();
                }
            })
            .catch(err => {
                loggingService("Users.loginWithThirdPartyApps.firebaseAuth.catch - err:");
                loggingService(err);
                reject(err);
            })
    })
};

/**
 *
 * @param type
 * @param data
 * @returns {Promise<unknown>}
 */
export const signUp = (type, data) => {
    if (type === 4) {
        return signUpWithEmailAndPassword(data);
    }

    let provider = null;
    if (type === 1) {
        //TODO: complete apple signUp
        //SignUp with Apple
    } else if (type === 2) {
        //SignUp with Facebook
        provider = new firebase.auth.FacebookAuthProvider();
    } else if (type === 3) {
        //SignUp with Google
        provider = new firebase.auth.GoogleAuthProvider();
    }
    return signUpWithThirdPartyApps(provider);
}

//OK
/**
 *
 * @param data
 * @returns {Promise<unknown>}
 */
const signUpWithEmailAndPassword = data => {
    return new Promise((resolve, reject) => {
        auth.createUserWithEmailAndPassword(data.email, data.password)
            .then(result => {
                if (result.user) {
                    let uid = result.user.uid;
                    addUserWithUID(uid, data)
                        .then(() => {
                            //addUserWithUID no retorna ningun objeto
                            findUserByUID(uid)
                                .then(doc => {
                                    if (doc.exists) {
                                        resolve(doc.data());
                                    } else {
                                        loggingService("User.signUpWithEmailAndPassword.createUserWithEmailAndPassword.then.if.addUserWithUID.then.findUserByUID.then.else - doc:");
                                        loggingService(doc);
                                        reject()
                                    }
                                })
                                .catch(err => {
                                    loggingService("User.signUpWithEmailAndPassword.createUserWithEmailAndPassword.then.if.addUserWithUID.then.findUserByUID.catch - err:");
                                    loggingService(err);
                                    reject(err);
                                })
                        })
                        .catch(err => {
                            loggingService("User.signUpWithEmailAndPassword.createUserWithEmailAndPassword.then.if.addUserWithUID.catch - err:");
                            loggingService(err);
                            reject(err);
                        })
                } else {
                    //TODO: revisar el result de createUserWithEmailAndPassword
                    loggingService("User.signUpWithEmailAndPassword.createUserWithEmailAndPassword.then.else - result:");
                    loggingService(result);
                    reject();
                }
            })
            .catch(err => {
                loggingService("User.signUpWithEmailAndPassword.createUserWithEmailAndPassword.catch - err:");
                loggingService(err);
                reject(err);
            });
    })
};

/**
 *
 * @param provider
 * @returns {Promise<unknown>}
 */
const signUpWithThirdPartyApps = provider => {
    loggingService("Users.signUpWithThirdPartyApps");
    return new Promise((resolve, reject) => {
        firebaseAuth(provider)
            .then((result) => {
                    loggingService("Users.signUpWithThirdPartyApps.firebaseAuth - result:");
                    loggingService(result);
                    const user = result.user;
                    const additionalUserInfo = result.additionalUserInfo;
                    if (user.uid) {
                        addUserWithUID(user.uid, additionalUserInfo)
                            .then(result => {
                                findUserByUID(user.uid)
                                    .then(doc => {
                                        if (doc.exists) {
                                            loggingService("Users.signUpWithThirdPartyApps.0011 - doc.data():");
                                            loggingService(doc.data());
                                            resolve(doc.data());
                                        } else {
                                            loggingService("Users.signUpWithThirdPartyApps.0010 - doc:");
                                            loggingService(doc);
                                            reject();
                                        }
                                    })
                                    .catch(err => {
                                        loggingService("Users.signUpWithThirdPartyApps.firebaseAuth.then.if.addUserWithUID.then.findUserByUID.catch - err:");
                                        loggingService(err);
                                        reject();
                                    })
                            })
                            .catch(err => {
                                loggingService("Users.signUpWithThirdPartyApps.firebaseAuth.then.if.addUserWithUID.catch - err:");
                                loggingService(err);
                                reject(err);
                            })
                    } else {
                        //TODO: validar la respuesta de firebaseAuth
                        loggingService("Users.signUpWithThirdPartyApps.firebaseAuth.then.else - result:");
                        loggingService(result);
                        reject();
                    }
                }
            ).catch(err => {
                loggingService("Users.signUpWithThirdPartyApps.firebaseAuth.catch - err:");
                loggingService(err);
                reject(err);
            }
        )
    })
}

/**
 *
 * @param uid
 * @param userData
 * @returns {Promise<unknown>}
 */
const addUserWithUID = (uid, userData) => {
    loggingService("Users.addUserWithUID");
    loggingService("Users.addUserWithUID - userData:");
    loggingService(userData);
    return new Promise((resolve, reject) => {
        const usersRef = db.collection(USERS);
        let now = new Date();
        let stringDate = dateFormat(now, "yyyy-mm-dd'T'HH:MM:ssp");
        //TODO: validar la respuesta(campos) de userData segun Apple, Google y Fb y ajustar los datos del usuario
        let email = null;
        let firstName = null;
        let lastName = null;
        let profilePics = null;
        if (userData.providerId === FACEBOOK_COM) {
            //userData for facebook
            if (userData.profile) {
                email = userData.profile.email;
                firstName = userData.profile.first_name + BLANK_SPACE + userData.profile.middle_name;
                lastName = userData.profile.last_name;
                profilePics = {
                    facebook: userData.profile.picture.data.url
                }
            }
        } else if (userData.providerId === GOOGLE_COM) {
            //userData for google
            if (userData.profile) {
                email = userData.profile.email;
                firstName = userData.profile.given_name;
                lastName = userData.profile.family_name;
                profilePics = {
                    google: userData.profile.picture
                }
            }
        } else {
            //userData for email and password
            email = userData.email;
            firstName = userData.fullName ? userData.fullName : "";
            lastName = userData.lastName ? userData.lastName : "";
            profilePics = userData.profilePics ? userData.profilePics : {};
        }


        usersRef.doc(uid).set({
            profile: {
                email,
                fcm: null,
                firstName,
                gender: 1,
                isAdmin: false,
                isPremium: false,
                isPremiumBonus: false,
                isPremiumManual: false,
                lastName,
                level: 0,
                somatotipo: null,
                tyc: stringDate,
                uid: uid,
            },
            profilePics,
            userStats: {
                caloriesBurned: 0,
                challengeDone: 0,
                recipesDone: 0,
                trainedSeconds: 0,
                workoutsDone: 0,
            }
        })
            .then(() => {
                //El documento se guardo correctamente
                loggingService("Users.addUserWithUID.set.then");
                resolve();
            })
            .catch(err => {
                loggingService("Users.addUserWithUID.set.catch - err:");
                loggingService(err);
                reject(err);
            });
    })
};

//OK
/**
 *
 * @param uid
 * @returns {Promise<unknown>}
 */
const findUserByUID = uid => {
    loggingService("Users.findUserByUID");
    return new Promise((resolve, reject) => {
        const usersRef = db.collection(USERS);
        usersRef.doc(uid).get()
            .then(doc => {
                loggingService("Users.findUserByUID.get.then - doc:");
                loggingService(doc);
                resolve(doc);
            })
            .catch(err => {
                loggingService("Users.findUserByUID.get.catch - err:");
                loggingService(err);
                reject(err);
            })
    })
}

//OK
/**
 *
 * @param provider
 * @returns {Promise<unknown>}
 */
let firebaseAuth = provider => {
    loggingService("Users.firebaseAuth");
    return new Promise((resolve, reject) => {
        firebase.auth().signInWithPopup(provider)
            .then(result => {
                loggingService("Users.firebaseAuth.firebase.auth().signInWithPopup.then - result:");
                loggingService(result);
                resolve(result)
            }).catch(function (err) {
            loggingService("Users.firebaseAuth.firebase.auth().signInWithPopup.catch - err:");
            loggingService(err);
            reject();
        });
    })
}

export const updatePaypalSubscriptionOnFirebase = (data, uid) => {
    loggingService("Users.updatePaypalSubscriptionOnFirebase");
    return new Promise((resolve, reject) => {
        const userRef = db.collection(USERS).doc(uid);

        userRef.update({
            "profile.isPremium": true,
            "purchaseUpdateSubscription.paypal": data
        })
            .then(() => {
                loggingService("Users.updatePaypalSubscriptionOnFirebase.update.then");
                resolve();
            })
            .catch(err => {
                loggingService("Users.updatePaypalSubscriptionOnFirebase.update.catch - err:");
                loggingService(err);
                reject(err);
            });

    })
}