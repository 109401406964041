import React, {Component} from "react";
import "./ForgotPasswordContainer.css";
import {auth} from "../../../config/Firebase";
import {loggingService} from "../../../services/Logging.js";

class ForgotPasswordContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            modalIsVisible: false
        };
    }

    onChangeText = (event) => {
        this.setState({
            ...this.state, email: event.target.value
        })
    };

    resetPasswordButtonOnCLick = () => {

        let emailAddress = this.state.email;

        auth.sendPasswordResetEmail(emailAddress).then(function () {
            // Email sent.
            loggingService("Email enviado");
            this.setState({...this.state, modalIsVisible: true});
        }).catch(function (error) {
            loggingService("Error al enviar el email. Error: " + error);
            // An error happened.
        });
    }

    getModalClassName = () => {
        return this.state.modalIsVisible ? "modal" : "hiddenModal";
    }

    closeModal = () => {
        this.setState({...this.state, modalIsVisible: false});
    }

    render() {
        return (
            <div className="ForgotPasswordContainer">
                <input type="email" name="email" onChange={this.onChangeText}
                       className=""/>
                <button onClick={() => this.resetPasswordButtonOnCLick()}>Restablecer contraseña</button>

                <div id="modal" className={this.getModalClassName()} onClick={() => this.closeModal()}>
                    <div className="modal-content" onClick={() => this.closeModal()}>
                        <p>...perfecto, ahora revisa tu bandeja de correo y sigue los pasos.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default (ForgotPasswordContainer);