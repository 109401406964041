import React, {Component} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import BannerContainer from "./containers/BannerContainer/BannerContainer";
import LandingContainer from "./containers/LandingContainer/LandingContainer";
import LoginContainer from "./containers/User/LoginContainer/LoginContainer";
import UserProfileContainer from "./containers/User/UserProfileContainer/UserProfileContainer";
import WelcomeContainer from "./containers/User/WelcomeContainer/WelcomeContainer";
import SignUpContainer from "./containers/User/SignUpContainer/SignUpContainer";
import CompleteProfileContainer from "./containers/User/CompleteProfileContainer/CompleteProfileContainer"
import SummaryPurchaseContainer from "./containers/SummaryPurchaseContainer/SummaryPurchaseContainer"
import ForgotPasswordContainer from "./containers/User/ForgotPasswordContainer/ForgotPasswordContainer.js"
import "./App.css";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="mainLayout">
                    <BannerContainer/>
                    <Route exact path="/" component={LandingContainer}/>
                    <Route exact path="/login" component={LoginContainer}/>
                    <Route exact path="/profile" component={UserProfileContainer}/>
                    <Route exact path="/signUp" component={SignUpContainer}/>
                    <Route exact path="/welcome" component={WelcomeContainer}/>
                    <Route exact path="/completeProfile" component={CompleteProfileContainer}/>
                    <Route exact path="/summaryPurchase" component={SummaryPurchaseContainer}/>
                    <Route exact path="/forgotPassword" component={ForgotPasswordContainer}/>
                </div>
            </Router>
        );
    }
}

export default App;