import React, {Component} from 'react';
import {connect} from 'react-redux';
import './WelcomeContainer.css';

class WelcomeContainer extends Component {

    validateUser() {
        if (this.props.user == null) {
            this.props.history.push("/");
            return true;
        }
    }

    render() {
        // if (this.validateUser()) {
        if (false) {
            return (
                <div></div>
            )
        } else {
            return (
                <div className="welcomeContainer">
                    <img src="/images/logo_welcome.png" className="welcomeContainer_logo" alt=""/>
                    <div className="welcomeContainer_text">
                        ¡Genial! Ya eres un guerrero Premium.<br/>
                        Descarga ORUX APP para iOS o Android y ponte manos a la obra.
                    </div>
                    <img src="/images/app-play-store.png" className="welcomeContainer_logo_store" alt=""/>
                </div>
            );
        }
    }
}

const mapDispatchToProps = dispatch => ({
    //login: payload => dispatch(login(payload))
});

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeContainer);