import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import "./SignUpContainer.css";
import {addUser} from "../../../store/actions/User"
import {loggingService} from "../../../services/Logging";
import * as EmailValidator from "email-validator";
import {signUp} from "../../../services/Firebase/Users";

class SignUpContainer extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            isFullNameValid: null,
            isEmailValid: null,
            isPasswordValid: null,
            isPasswordConfirmationValid: null,
            isModalVisible: false
        };
    }

    /*Variables*/
    form = {
        fullName: null,
        email: null,
        password: null,
        passwordConfirmation: null
    };

    //OK
    /**
     * Luego de ejecutado el constructor, se ejecuta este metodo que pertenece al ciclo de vida de react.
     * Se analiza si el usuario existe y si es un nuevo usuario para poder hacer redireccion a completeProfile
     * newUser es el flag que nos indica si se hizo signUp pero aún no se ha completado la informacion de gender que corresponde
     * a la siguiente pantalla luego de signUp
     * @param props
     * @returns {null}
     */
    static getDerivedStateFromProps = props => {
        if (props.user)
            if (props.user.newUser) {
                props.history.push("/completeProfile");
            }
        return null;
    };

    /**
     *
     * @param type
     */
    singUpOnClick = type => {
        loggingService("SignUpContainer.singUpOnClick");
        let isFormValid = false;
        if (type === 4) {
            isFormValid = this.state.isFullNameValid && this.state.isEmailValid && this.state.isPasswordValid && this.state.isPasswordConfirmationValid;
        }
        if ((type === 4 && isFormValid) || (type !== 4)) {
            signUp(type, type === 4 ? this.form : null)
                .then(result => {
                    this.props.addUser(result);
                })
                .catch(err => {
                    loggingService("SignUpContainer.singUpOnClick.singUp.catch - err:");
                    loggingService(err);
                    this.setState({...this.state, isModalVisible: true})
                })
        }
    };

    onChangeText = (event) => {
        this.form = {
            ...this.form, [event.target.name]: event.target.value
        };
        this.validateEmailAndPassword();
    };

    validateEmailAndPassword = () => {
        loggingService("SignUpContainer.validateEmailAndPassword", false);

        let isFullNameValid = this.state.isFullNameValid;
        if (this.form.fullName != null) {
            this.form.fullName.length === 0 ? isFullNameValid = null : isFullNameValid = this.form.fullName.length > 0;
        }
        loggingService("LoginContainer.validateEmailAndPassword - isFullNameValid: " + isFullNameValid)

        let isEmailValid = this.state.isEmailValid;
        if (this.form.email !== null) {
            this.form.email.length === 0 ? isEmailValid = null : isEmailValid = EmailValidator.validate(this.form.email);
        }

        let isPasswordValid = this.state.isPasswordValid;
        if (this.form.password !== null) {
            this.form.password.length === 0 ? isPasswordValid = null : isPasswordValid = this.form.password.length >= 6;
        }

        let isPasswordConfirmationValid = this.state.isPasswordConfirmationValid;
        if (this.form.password !== null && this.form.passwordConfirmation !== null) {
            this.form.passwordConfirmation.length === 0 ? isPasswordConfirmationValid = null : isPasswordConfirmationValid = this.form.password === this.form.passwordConfirmation;
        }

        this.setState({...this.state, isFullNameValid, isEmailValid, isPasswordValid, isPasswordConfirmationValid});

        return !!(isFullNameValid && isEmailValid && isPasswordValid && isPasswordConfirmationValid);
    }

    getInputNameClassName = () => {
        if (this.state.isFullNameValid === null) {
            return "singUpC_f_dc_input";
        }
        return this.state.isFullNameValid ? "singUpC_f_dc_input singUpC_f_dc_input_validated" : "singUpC_f_dc_input singUpC_f_dc_input_error";
    }

    getInputEmailClassName = () => {
        if (this.state.isEmailValid === null) {
            return "singUpC_f_dc_input";
        }
        return this.state.isEmailValid ? "singUpC_f_dc_input singUpC_f_dc_input_validated" : "singUpC_f_dc_input singUpC_f_dc_input_error";
    }

    getInputPasswordClassName = () => {
        if (this.state.isPasswordValid === null) {
            return "singUpC_f_dc_input";
        }
        return this.state.isPasswordValid ? "singUpC_f_dc_input singUpC_f_dc_input_validated" : "singUpC_f_dc_input singUpC_f_dc_input_error";
    }

    getInputConfirmPasswordClassName = () => {
        if (this.state.isPasswordConfirmationValid === null) {
            return "singUpC_f_dc_input";
        }
        return this.state.isPasswordConfirmationValid ? "singUpC_f_dc_input singUpC_f_dc_input_validated" : "singUpC_f_dc_input singUpC_f_dc_input_error";
    }

    render() {
        return (
            <div className="signUpContainer">

                <div className="title_2"> Crear cuenta con:</div>

                <div className="singUpC_buttonsContainer">
                    <div className="singUpC_bc_c2">
                        <div className="singUpC_bc_c2_c3" onClick={() => this.singUpOnClick(1)}>
                            <img alt="Continuar con Apple" src="/images/apple_128.png"
                                 className="singUpC_bc_c2_c3_appleImage"/>
                            <span>Continuar con Apple</span>
                        </div>
                    </div>

                    <div className="singUpC_bc_c2">
                        <div className="singUpC_bc_c2_c3" onClick={() => this.singUpOnClick(2)}>
                            <img alt="Continuar con Facebook" src="/images/facebook_128.png"
                                 className="singUpC_bc_c2_c3_fbImage"/>
                            <span>Continuar con Facebook</span>
                        </div>
                    </div>

                    <div className="singUpC_bc_c2">
                        <div className="singUpC_bc_c2_c3" onClick={() => this.singUpOnClick(3)}>
                            <img alt="Continuar con Google" src="/images/google_64.png"
                                 className="singUpC_bc_c2_c3_googleImage"/>
                            <span>Continuar con Google</span>
                        </div>
                    </div>
                </div>

                <div className="singUpC_form">

                    <div className="title_3">Crear cuenta con mi correo</div>

                    <div className="singUpC_f_dataContainer">
                        <div className="singUpC_f_dc_name">
                            <label className="singUpC_f_dc_label">Nombre y apellido</label>
                            <input type="text" name="fullName" onChange={this.onChangeText}
                                   className={this.getInputNameClassName()}/>
                        </div>

                        <div className="singUpC_f_dc_email">
                            <label className="singUpC_f_dc_label">Correo electr&oacute;nico</label>
                            <input type="email" name="email" onChange={this.onChangeText}
                                   className={this.getInputEmailClassName()}/>
                        </div>

                        <div className="singUpC_f_dc_password">
                            <label className="singUpC_f_dc_label">Contrase&ntilde;a</label>
                            <input type="password" name="password" onChange={this.onChangeText}
                                   className={this.getInputPasswordClassName()}/>
                        </div>

                        <div className="singUpC_f_dc_password">
                            <label className="singUpC_f_dc_label">Confirmar Contrase&ntilde;a</label>
                            <input type="password" name="passwordConfirmation" onChange={this.onChangeText}
                                   className={this.getInputConfirmPasswordClassName()}/>
                        </div>
                    </div>

                    <div className="singUpC_f_button">
                        <button className="button button_1" onClick={() => this.singUpOnClick(4)}>CREAR CUENTA</button>
                    </div>
                </div>

                <div className="title_5">
                    <Link to="/login">Ya tienes una cuenta</Link>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    addUser: payload => dispatch(addUser(payload))
});

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);