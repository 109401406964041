import React, {Component} from "react";
import {connect} from "react-redux";
import Image from "react-bootstrap/Image";
import "./BannerContainer.css";
import {logout} from "../../store/actions/User.js"
import {resetSelectedSubscription} from "../../store/actions/PurchaseInformation.js"
import {withRouter} from "react-router-dom";
import {loggingService} from "../../services/Logging.js";

class BannerContainer extends Component {

    // constructor(props) {
    //     loggingService("BannerContainer.constructor");
    //     super(props);
    // }

    //----------BANNER BUTTONS----------

    login = () => {
        this.props.history.push("/login");
    };

    signUp = () => {
        this.props.history.push("/signUp");
    };

    bannerButtons = () => {
        return (
            <div className="bannerButtons">
                <button onClick={() => this.login()}>INICIAR SESI&Oacute;N</button>
                <button onClick={() => this.signUp()}>CREAR CUENTA</button>
            </div>
        )
    };

    //----------BANNER BUTTONS----------

    //----------BANNER INFO----------

    getUserProfilePicture = () => {
        if (this.props.user.profilePicture) {
            return this.props.user.profilePicture;
        }
    };

    showPremium = () => {
        if (this.props.user.isPremium)
            return <div className="bannerUserInfo_isPremium">Premium</div>
    };

    bannerInfo = () => {
        return (
            <div className="bannerUserInfo">
                <img src={this.getUserProfilePicture()} alt=""/>
                {this.showPremium()}
                <div className="bannerUserInfo_container">
                    <div className="bannerUserInfo_userName"
                         onClick={() => this.props.history.push("/profile")}>{this.props.user.userName.toUpperCase()}</div>
                    <div>/</div>
                    <div className="bannerUserInfo_logout" onClick={() => this.logout()}>CERRAR SESIÓN</div>
                </div>
            </div>
        );
    };

    logout = () => {
        this.props.logout();
        this.props.resetSelectedSubscription();
    };

    //----------BANNER INFO----------

    setComponent = () => {
        loggingService("BannerContainer.setComponent");
        loggingService("BannerContainer.setComponent - this.props.user:");
        loggingService(this.props);
        const {pathname} = this.props.location;
        if (this.props.user) {
            return this.bannerInfo();
        } else {
            if (pathname !== "/login" || pathname !== "/signUp") {
                return this.bannerButtons();
            }
        }
        return null;
    };

    render() {
        return (
            <div className="banner">
                <a href="/"><Image src="/images/logo_banner.png" alt="logobanner"/></a>
                {this.setComponent()}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    logout: payload => dispatch(logout(payload)),
    resetSelectedSubscription: payload => dispatch(resetSelectedSubscription(payload))
});

const mapStateToProps = state => ({
    user: state.user
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerContainer));