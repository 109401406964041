export const getPriceForSubscription = monthSelected => {
    if (monthSelected === 3) {
        return 35
    } else if (monthSelected === 6) {
        return 60
    } else if (monthSelected === 12) {
        return 70
    }
}

export const getSubscriptionTitle = monthSelected => {
    if (monthSelected === 3) {
        return "PLAN BÁSICO";
    } else if (monthSelected === 6) {
        return "ABC";
    } else if (monthSelected === 12) {
        return "MÁS VENDIDO";
    }
}

export const getSubscriptionDescription = monthSelected => {
    if (monthSelected === 3) {
        return "ABC"
    } else if (monthSelected === 6) {
        return "15%"
    } else if (monthSelected === 12) {
        return "50%"
    }
}

export const getSubscriptionPrice = monthSelected => {
    if (monthSelected === 3) {
        return 35
    } else if (monthSelected === 6) {
        return 60
    } else if (monthSelected === 12) {
        return 70
    }
}