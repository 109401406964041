import React, {Component} from "react";
import {connect} from "react-redux";
import "./CompleteProfileContainer.css";
import {finishSingUp} from "../../../store/actions/User.js"

class CompleteProfileContainer extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            womanClassName: "completeProfileC_womanBox",
            manClassName: "completeProfileC_manBox",
            womanButtonClassName: "completeProfileC_wb_b_button",
            manButtonClassName: "completeProfileC_mb_b_button"
        };
    }

    /**
     * Luego de ejecutado el constructor, se ejecuta este metodo que pertenece al ciclo de vida de react.
     * Se analiza si el usuario existe y si no es un nuevo usuario para poder hacer redireccion a profile
     * @param props
     * @returns {null}
     */
    static getDerivedStateFromProps = props => {
        if (props.user) {
            if (props.user.newUser === false) {
                props.history.push("/profile");
            }
        } else {
            props.history.push("/");
        }
        return null;
    };

    /*Variables*/
    genderSelected = {
        woman: false,
        man: false
    }

    selectWomanOnClick = () => {
        this.genderSelected.woman = true;
        this.genderSelected.man = false;
        this.setState({
            womanClassName: "completeProfileC_womanBox completeProfileC_womanBoxSelected",
            manClassName: "completeProfileC_manBox",
            womanButtonClassName: "completeProfileC_wb_b_button completeProfileC_wb_b_buttonSelected",
            manButtonClassName: "completeProfileC_mb_b_button"
        });
    }

    selectMan = () => {
        this.genderSelected.woman = false;
        this.genderSelected.man = true;
        this.setState({
            womanClassName: "completeProfileC_womanBox",
            manClassName: "completeProfileC_manBox completeProfileC_manBoxSelected",
            womanButtonClassName: "completeProfileC_wb_b_button",
            manButtonClassName: "completeProfileC_mb_b_button completeProfileC_mb_b_buttonSelected"
        });
    }

    finishSingUp() {
        this.props.finishSingUp(this.genderSelected);
    }

    getWomanDivClassName = () => {
        return this.state.womanClassName;
    }

    getWomanButtonClassName = () => {
        return this.state.womanButtonClassName;
    }

    getManDivClassName = () => {
        return this.state.manClassName;
    }

    getManButtonClassName = () => {
        return this.state.manButtonClassName;
    }

    render() {
        return (
            <div className="completeProfileContainer">

                <div className="completeProfileC_title">Selecciona tu preferencia</div>

                <div className="completeProfileC_subtitleWoman">Mujer</div>

                {/*completeProfileC_womanBox*/}
                <div className={this.getWomanDivClassName()} onClick={() => this.selectWomanOnClick()}>

                    <img alt="" src="images/registerWoman.png"/>

                    <div className="completeProfileC_wb_description">Planes de entrenamiento y nutrición diseñadas
                        para las mujeres.
                    </div>

                    <div className="completeProfileC_wb_buttons">
                        <div className={this.getWomanButtonClassName()}>Tonifica tus musculos</div>
                        <div className={this.getWomanButtonClassName()}>Elimina grasa</div>
                        <div className={this.getWomanButtonClassName()}>Define tu cuerpo</div>
                    </div>
                </div>

                <div className="completeProfileC_subtitleMan">Hombre</div>

                {/*completeProfileC_manBox*/}
                <div className={this.getManDivClassName()} onClick={() => this.selectMan()}>

                    <img alt="" src="images/registerMan.png"/>

                    <div className="completeProfileC_mb_description">Planes de entrenamiento y nutrición diseñados
                        para los hombres.
                    </div>

                    <div className="completeProfileC_mb_buttons">
                        <div className={this.getManButtonClassName()}>Construye musculo</div>
                        <div className={this.getManButtonClassName()}>Quema grasa</div>
                        <div className={this.getManButtonClassName()}>Define tu cuerpo</div>
                    </div>
                </div>

                <div className="completeProfileC_finishButton">
                    <button className="button button_2" onClick={() => this.finishSingUp()}>FINALIZAR
                    </button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    finishSingUp: payload => dispatch(finishSingUp(payload))
});

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfileContainer);