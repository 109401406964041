export const SET_SELECTED_SUBSCRIPTION = 'SET_SELECTED_SUBSCRIPTION';
export const RESET_SELECTED_SUBSCRIPTION = 'RESET_SELECTED_SUBSCRIPTION';

export const setSelectedSubscription = payload => ({
    type: SET_SELECTED_SUBSCRIPTION,
    payload
});

export const resetSelectedSubscription = payload => ({
    type: RESET_SELECTED_SUBSCRIPTION,
    payload
});