import {SET_USER_DATA, ADD_USER, FINISH_SING_UP, LOGOUT} from '../actions/User';
import {formatUserData} from '../../services/FormatData'

export const user = (state = {}, action) => {
    let userData = null;
    switch (action.type) {
        case SET_USER_DATA:
            userData = formatUserData(action.payload, false);
            return {
                ...state,
                ...userData
            };
        case ADD_USER:
            userData = formatUserData(action.payload, true);
            return {
                ...state,
                ...userData
            };
        case FINISH_SING_UP:
            let gender = 0;
            if (action.payload.man) {
                gender = 1;
            }

            userData = {
                gender,
                newUser: false
            };

            return {
                ...state,
                ...userData
            };
        case LOGOUT:
            return null;
        default:
            return state
    }
};