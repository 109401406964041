import {SET_SELECTED_SUBSCRIPTION, RESET_SELECTED_SUBSCRIPTION} from '../actions/PurchaseInformation';

export const purchaseInformation = (state = {}, action) => {
    switch (action.type) {
        case SET_SELECTED_SUBSCRIPTION:
            let monthSelected = action.payload;
            let selectedSubscription = {
                monthSelected: monthSelected
            }
            return {...state, selectedSubscription: selectedSubscription};
        case RESET_SELECTED_SUBSCRIPTION:
            return {...state, selectedSubscription: {monthSelected: 12}};
        default:
            return state
    }
};