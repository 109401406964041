import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import "./LandingContainer.css";
import {setSelectedSubscription} from "../../store/actions/PurchaseInformation"
import {loggingService} from "../../services/Logging.js"
import {
    getSubscriptionTitle,
    getSubscriptionDescription,
    getSubscriptionPrice
} from "../../services/SelectedSubscription";

class LandingContainer extends Component {

    constructor(props) {
        loggingService("LandingContainer.constructor");
        super(props);
        this.state = {
            monthSelected: this.props.purchaseInformation.selectedSubscription.monthSelected
        };

    }

    componentDidMount() {
        loggingService("LandingContainer.componentDidMount");
        //window.scrollTo(100, 0);
        //document.body.scrollTop = 0; // For Safari
        //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    selectMonthButtOnClick = monthSelected => {
        loggingService("LandingContainer.selectMonthButtOnClick");

        //Se almacena en el store global la información de la suscripcion seleccionada
        this.props.setSelectedSubscription(monthSelected);

        //Se almacena en el store local la informacion del mes seleccionado
        this.setState({monthSelected})
    };

    buyButtonOnClick = () => {
        loggingService("LandingContainer.buyButtonOnClick");
        /*Si el usuario existe en el store, es porque ya hizo login, en ese caso lo envio a summaryPurchase
        para que finalice la compra, de lo contrario lo envio a la pagina de login*/
        if (this.props.user) {
            this.props.history.push("/summaryPurchase");
        } else {
            this.props.history.push("/login");
        }
    };

    getPrice = () => {
        return getSubscriptionPrice(this.state.monthSelected);
    }

    getClassName = (month) => {
        return this.state.monthSelected === month ? "landingC_pf_pb_bc_buttonSelected commonButton" : "landingC_pf_pb_bc_button commonButton";
    }

    getTitle = () => {
        loggingService("LandingContainer.getTitle");
        return getSubscriptionTitle(this.state.monthSelected);
    }

    getDescription = () => {
        loggingService("LandingContainer.getDescription");
        return getSubscriptionDescription(this.state.monthSelected);
    }

    render() {
        return (
            <div className="landingContainer">
                <div className="title_1">TU MOMENTO ES AHORA</div>
                <div className="landingC_purchaseForm">
                    <div className="subtitle_1">Elige tu suscripción</div>
                    <div className="landingC_pf_purchaseButtons">
                        <div className="landingC_pf_pb_buttonContainer">
                            <button
                                className={this.getClassName(3)}
                                onClick={() => this.selectMonthButtOnClick(3)}>3 MESES
                            </button>
                        </div>
                        <div className="landingC_pf_pb_buttonContainer">
                            <button
                                className={this.getClassName(12)}
                                onClick={() => this.selectMonthButtOnClick(12)}>12 MESES
                            </button>
                        </div>
                        <div className="landingC_pf_pb_buttonContainer">
                            <button
                                className={this.getClassName(6)}
                                onClick={() => this.selectMonthButtOnClick(6)}>6 MESES
                            </button>
                        </div>
                    </div>
                    <div className="landingC_pf_container">
                        <div className="landingC_pf_c_title">{this.getTitle()}</div>
                        <div className="landingC_pf_c_description">{this.getDescription()}</div>

                        <div className="landingC_pf_c_price">
                            <div className="landingC_pf_c_p_integers">${this.getPrice()}.</div>
                            <div className="landingC_pf_c_p_decimals">00</div>
                        </div>
                        <div className="landingC_pf_c_currency">Dólares</div>
                        <div className="landingC_pf_c_planDescription">
                            <h1>Entranamiento</h1>
                            <h1>Nutrición</h1>
                            <h1>Chat y asesoria online</h1>
                        </div>
                        <button className="landingC_pf_c_buyButton"
                                onClick={() => this.buyButtonOnClick()}>COMPRAR
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSelectedSubscription: payload => dispatch(setSelectedSubscription(payload))
    }
};

const mapStateToProps = state => ({
    purchaseInformation: state.purchaseInformation,
    user: state.user
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingContainer));