import {db} from "../../config/Firebase";
import {DISCOUNT_CODES} from "../../config/Firebase/DBCollectionListName";
import {loggingService} from "../Logging.js";

export const findDiscountCodeByDocId = codeId => {
    loggingService("DiscountCodes.findDiscountCodeByDocId");
    return new Promise((resolve, reject) => {
        const docRef = db.collection(DISCOUNT_CODES);
        docRef.doc(codeId).get()
            .then(doc => {
                if (doc.exists) {
                    resolve(doc.data());
                } else {
                    //No se encontro el documento
                    loggingService("DiscountCodes.findDiscountCodeByDocId.0002");
                    resolve(null);
                }
            })
            .catch(err => {
                loggingService("DiscountCodes.findDiscountCodeByDocId.0001 - err:");
                loggingService(err);
                reject(err);
            })
    })
}