import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import "./LoginContainer.css";
import {setUserData} from "../../../store/actions/User"
import * as EmailValidator from 'email-validator';
import {loggingService} from "../../../services/Logging";
import {login} from "../../../services/Firebase/Users"

class LoginContainer extends Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            isEmailValid: null,
            isPasswordValid: null,
            isModalVisible: false
        };
    }


    /* Variables */
    form = {
        email: null,
        password: null
    };

    /**
     * Luego de ejecutado el constructor, se ejecuta este metodo que pertenece al ciclo de vida de react.
     * Se analiza si el usuario existe para poder hacer redireccion a profile en caso de que el usuario ya este
     * logeado
     * @param props
     * @returns {null}
     */
    static getDerivedStateFromProps = (props) => {
        //Se valida si en el store globar existe un usuario y en caso positivo se redirecciona a profile
        if (props.user) {
            props.history.push("/profile");
        }
        return null;
    };

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    loginOnClick = type => {
        loggingService("LoginContainer.login");
        let isFormValid = false;
        if (type === 4) {
            isFormValid = this.state.isEmailValid && this.state.isPasswordValid;
        }

        if ((type === 4 && isFormValid) || (type !== 4)) {
            login(type, type === 4 ? this.form : null)
                .then((result) => {
                    this.props.setUserData(result);
                }).catch(err => {
                loggingService("LoginContainer.login.login.catch - err:");
                loggingService(err);
                this.setState({...this.state, isModalVisible: true})
            })
        }
    }

    onChangeText = (event) => {
        this.form = {
            ...this.form, [event.target.name]: event.target.value
        };
        this.validateEmailAndPassword();
    };

    validateEmailAndPassword = () => {
        loggingService("LoginContainer.validateEmailAndPassword", false);

        let isEmailValid = this.state.isEmailValid;
        if (this.form.email !== null) {
            this.form.email.length === 0 ? isEmailValid = null : isEmailValid = EmailValidator.validate(this.form.email);
        }

        let isPasswordValid = this.state.isPasswordValid;
        if (this.form.password !== null) {
            this.form.password.length === 0 ? isPasswordValid = null : isPasswordValid = this.form.password.length >= 6;
        }

        this.setState({isEmailValid, isPasswordValid});

        return !!(isEmailValid && isPasswordValid);
    }

    getInputEmailClassName = () => {
        loggingService("getInputEmailErrorClassName - state.isEmailValid: " + this.state.isEmailValid, false);
        if (this.state.isEmailValid === null) {
            return "loginC_f_c_input";
        }
        return this.state.isEmailValid ? "loginC_f_c_input loginC_f_c_input_validated" : "loginC_f_c_input loginC_f_c_input_error";
    }

    getInputPasswordClassName = () => {
        loggingService("getInputPasswordErrorClassName - state.isPasswordValid: " + this.state.isPasswordValid, false);
        if (this.state.isPasswordValid === null) {
            return "loginC_f_c_input";
        }
        return this.state.isPasswordValid ? "loginC_f_c_input loginC_f_c_input_validated" : "loginC_f_c_input loginC_f_c_input_error";
    }

    getModalClassName = () => {
        return this.state.isModalVisible ? "modal" : "hiddenModal";
    }

    closeModal = () => {
        this.setState({...this.state, isModalVisible: false});
    }

    render() {
        return (
            <div className="loginContainer">

                <div className="title_2"> Iniciar sesión</div>

                <div className="loginC_loginButtonsContainer">
                    <div className="loginC_lbc_c2">
                        <div className="loginC_lbc_c2_c3" onClick={() => this.loginOnClick(1)}>
                            <img alt="Continuar con Apple" src="/images/apple_128.png"
                                 className="loginC_lbc_c2_c3_appleImage"/>
                            <span>Continuar con Apple</span>
                        </div>
                    </div>

                    <div className="loginC_lbc_c2">
                        <div className="loginC_lbc_c2_c3" onClick={() => this.loginOnClick(2)}>
                            <img alt="Continuar con Facebook" src="/images/facebook_128.png"
                                 className="loginC_lbc_c2_c3_fbImage"/>
                            <span>Continuar con Facebook</span>
                        </div>
                    </div>

                    <div className="loginC_lbc_c2">
                        <div className="loginC_lbc_c2_c3" onClick={() => this.loginOnClick(3)}>
                            <img alt="Continuar con Google" src="/images/google_64.png"
                                 className="loginC_lbc_c2_c3_googleImage"/>
                            <span>Continuar con Google</span>
                        </div>
                    </div>
                </div>

                <div className="loginC_form">

                    <div className="title_3">Iniciar sesión con mi correo</div>

                    <div className="loginC_f_container">
                        <div className="loginC_f_c_c2">
                            <label className="loginC_f_c_label">Correo electrónico</label>
                            <input type="email" name="email" onChange={this.onChangeText}
                                   className={this.getInputEmailClassName()}/>
                        </div>

                        <div>
                            <label className="loginC_f_c_label">Contraseña</label>
                            <input type="password" name="password" onChange={this.onChangeText}
                                   className={this.getInputPasswordClassName()}/>
                        </div>
                    </div>

                    <div className="title_4">
                        <Link to="/forgotPassword">¿Olvidate tu contraseña?</Link>
                    </div>

                    <div className="loginC_f_acceptButton">
                        <button className="button button_1" onClick={() => this.loginOnClick(4)}>INICIAR</button>
                    </div>
                </div>

                <div className="title_5">
                    <Link to="/signUp">Crear cuenta</Link>
                </div>

                {/*Modal*/}
                <div id="modal" className={this.getModalClassName()} onClick={() => this.closeModal()}>
                    <div className="modal-content" onClick={() => this.closeModal()}>
                        <p>...ups!!, algo pasa, quiza seamos nosotros, intentalo nuevamente por favor.</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setUserData: payload => dispatch(setUserData(payload))
});

const mapStateToProps = state => ({user: state.user});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);