import {SET_PERSONAL_DATA_BTN} from '../actions/ProfileContainer';

export const profileContainer = (state = {}, action) => {
    switch (action.type) {
        case SET_PERSONAL_DATA_BTN:
            const profileContainer = {
                personalDataBtn: action.payload
            };
            return {
                ...state,
                ...profileContainer
            };
        default:
            return state
    }
};